const settings = {
  domain: process.env.REACT_APP_API_GATEWAY,
  fetchDomains: process.env.REACT_APP_API_DOMAINS,
  s3URL: process.env.REACT_APP_S3_URL,
  s3Bucket: process.env.REACT_APP_S3_BUCKET,
  api: '',
  mode: 'live',
  newsCount: 10,
  formCert: 'contact_cert_ge',
  formEdugain: 'contact_edugain',
  formVps: 'contact_virtualization',
  formVpsCustom: 'contact_virtualization_custom',
  formHosting: 'contact_hosting',
  formSoftware: 'contact_licensed_software',
  desktopBreakpoint: 1199,
  padBreakpoint: 899,
  errorMessageTimeout: 30000,
  googleReCaptchaSiteKey: process.env.REACT_APP_GOOGLERECAPTCHA_SITEKEY,
  googleReCaptchaSecretKey: process.env.REACT_APP_GOOGLERECAPTCHA_SECRETKEY,
  googleTagManager: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  authURL: process.env.REACT_APP_KEYCLOAK_URL,
  authClientID: process.env.REACT_APP_CLIENT_ID,
  authClientSecret: process.env.REACT_APP_CLIENT_SECRET,
  authUserID: process.env.REACT_APP_USER_ID,
  authUserSecret: process.env.REACT_APP_USER_SECRET,
  clientsUi: 'clients-ui/',
  myGrenaUrl: process.env.REACT_APP_MY_GRENA_URL,
  app_version: '0.1.3',
}

export default settings