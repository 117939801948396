export const mainMenuItems = [
  {
    id: '1',
    title: {
      ka: 'სერვისები',
      en: 'Services'
    },
    has_submenu: true,
    submenu_name: 'services',
    go_to_url: '',
    priority: '1',
    is_visible: true,
  },
  {
    id: '2',
    title: {
      ka: 'სწავლა',
      en: 'Education'
    },
    has_submenu: true,
    submenu_name: 'education',
    go_to_url: '/education',
    priority: '2',
    is_visible: true,
  },
  {
    id: '3',
    title: {
      ka: 'გრენას შესახებ',
      en: 'About GRENA'
    },
    has_submenu: true,
    submenu_name: 'about_grena',
    go_to_url: '',
    priority: '5',
    is_visible: true,
  },
  {
    id: '4',
    title: {
      ka: 'პროექტები',
      en: 'Projects'
    },
    has_submenu: false,
    submenu_name: '',
    go_to_url: '/projects',
    priority: '4',
    is_visible: true,
  },
  // {
  //   id: '5',
  //   title: {
  //     ka: 'ახალი ამბები',
  //     en: 'News'
  //   },
  //   has_submenu: false,
  //   submenu_name: '',
  //   go_to_url: '/news',
  //   priority: '3',
  //   is_visible: true,
  // },
]

export const subMenuItems = [
  {
    id: '1',
    title: {
      ka: 'ინტერნეტი / GEANT',
      en: 'Internet / GEANT'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/internet',
    priority: '1',
    is_visible: true,
  },
  {
    id: '2',
    title: {
      ka: 'ვირტუალიზაცია',
      en: 'Virtualization'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/virtualization',
    priority: '2',
    is_visible: true,
  },
  {
    id: '3',
    title: {
      ka: 'ჰოსტინგი',
      en: 'Hosting'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/hosting',
    priority: '3',
    is_visible: true,
  },
  {
    id: '4',
    title: {
      ka: 'ლიცენზირებული პროგრამები',
      en: 'Licensed Software'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/licensed-software',
    priority: '4',
    is_visible: true,
  },
  {
    id: '5',
    title: {
      ka: 'დომენების რეგისტრაცია',
      en: 'Domain Registration'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/domains',
    priority: '5',
    is_visible: true,
  },
  {
    id: '6',
    title: {
      ka: 'eduroam',
      en: 'Eduroam'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/eduroam',
    priority: '6',
    is_visible: true,
  },
  {
    id: '7',
    title: {
      ka: 'eduGAIN',
      en: 'eduGAIN'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/edugain',
    priority: '7',
    is_visible: true,
  },
  {
    id: '8',
    title: {
      ka: 'CERT-GE',
      en: 'CERT-GE'
    },
    has_submenu: false,
    submenu_name: 'services',
    go_to_url: '/cert-ge',
    priority: '8',
    is_visible: true,
  },
  {
    id: '9',
    title: {
      ka: 'Cisco-ს აკადემია',
      en: 'Cisco Academy'
    },
    has_submenu: false,
    submenu_name: 'education',
    go_to_url: '/education/1',
    priority: '1',
    is_visible: true,
  },
  {
    id: '10',
    title: {
      ka: 'LPI-ს აკადემია',
      en: 'LPI Academy'
    },
    has_submenu: false,
    submenu_name: 'education',
    go_to_url: '/education/2',
    priority: '2',
    is_visible: true,
  },
  {
    id: '11',
    title: {
      ka: 'MS SQL-ის მონაცემთა ბაზები',
      en: 'MS SQL Databases'
    },
    has_submenu: false,
    submenu_name: 'education',
    go_to_url: '/education/3',
    priority: '3',
    is_visible: true,
  },
  {
    id: '12',
    title: {
      ka: 'Oracle-ის მონაცემთა ბაზები',
      en: 'Oracle Databases'
    },
    has_submenu: false,
    submenu_name: 'education',
    go_to_url: '/education/4',
    priority: '4',
    is_visible: true,
  },
  {
    id: '13',
    title: {
      ka: 'ჩვენ შესახებ',
      en: 'About Us'
    },
    has_submenu: false,
    submenu_name: 'about_grena',
    go_to_url: '/about-us',
    priority: '1',
    is_visible: true,
  },
  {
    id: '14',
    title: {
      en: 'Rules (AUP)'
    },
    has_submenu: false,
    submenu_name: 'about_grena',
    go_to_url: '/rules',
    priority: '2',
    is_visible: true,
  },
  {
    id: '15',
    title: {
      ka: 'ჩვენი გუნდი',
      en: 'Our Team'
    },
    has_submenu: false,
    submenu_name: 'about_grena',
    go_to_url: '/team',
    priority: '3',
    is_visible: true,
  },
  {
    id: '16',
    title: {
      ka: 'დამფუძნებლები',
      en: 'Founders'
    },
    has_submenu: false,
    submenu_name: 'about_grena',
    go_to_url: '/founders',
    priority: '4',
    is_visible: true,
  },
]