import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import {
  DomainSearchResultSimilarContainerBox,
  DomainSearchResultSimilarIconBox,
  DomainSearchResultSimilarGreenStatusBox,
  DomainSearchResultSimilarRedStatusBox,
  DomainSearchResultSimilarPriceBox,
  DomainSearchResultSimilarNameBox,
  DomainSearchResultPopupBox,
  DomainSearchResultPopupVectorBox
} from '../../themes/boxDomains'
import { DomainsSearchResultSmallButton } from '../../themes/button'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const DomainSearchResultSimilarItem = ({ domain, status, ext, domains, specialDomains, prices }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)
  
  const [openPopup, setOpenPopup] = useState(false)

  const handleRegister = () => {
    // const langName = lang === 'en' ? 'eng' : 'geo'
    // const url = `https://domain.grena.ge/index.php?a=reg&lang=${langName}&domain=${domain}`
    window.open(settings.myGrenaUrl, '_blank')
  }

  const handleTransfer = () => {
    // const langName = lang === 'en' ? 'eng' : 'geo'
    // const url = `https://domain.grena.ge/index.php?a=transfer&lang=${langName}&domain=${domain}`
    window.open(settings.myGrenaUrl, '_blank')
  }

  let statusBlock = ''
  let button = ''

  if (status === 'Ok') {
    statusBlock = <DomainSearchResultSimilarRedStatusBox>{constants.unavailable[lang]}</DomainSearchResultSimilarRedStatusBox>
    button = <DomainsSearchResultSmallButton variant='contained' disableRipple onClick={handleTransfer}>{constants.transfer[lang]}</DomainsSearchResultSmallButton>
  }

  if (status === 'Available') {
    statusBlock = <DomainSearchResultSimilarGreenStatusBox>{constants.free[lang]}</DomainSearchResultSimilarGreenStatusBox>
    button = <DomainsSearchResultSmallButton variant='contained' disableRipple onClick={handleRegister}>{constants.register[lang]}</DomainsSearchResultSmallButton>
  }

  if (status === 'Unavailable') {
    statusBlock = <DomainSearchResultSimilarRedStatusBox>{constants.invalidDomain[lang]}</DomainSearchResultSimilarRedStatusBox>
  }

  let descriptionObject = {}
  if (ext === '.ge') {
    descriptionObject = domains.find(item => item.domain_name === ext)
  } else {
    descriptionObject = specialDomains.find(item => item.spec_domain_name === ext)
  }
  const description = descriptionObject ? descriptionObject.info_text[lang] : ''

  const getDomainPrice = () => {
    let price = 0

    const splittedDomain = domain.split('.')

    let domainPriceName = ext
    if (splittedDomain.length > 2) {
      domainPriceName = '.' + splittedDomain[1] + '.' + splittedDomain[2]
    }    

    const isPremium = splittedDomain.length === 2 && splittedDomain[0].length === 2

    const filteredPriceArray = prices.filter(item => item.name === domainPriceName && item.is_premium === isPremium)

    if (filteredPriceArray.length > 0 && filteredPriceArray[0].registration_price && filteredPriceArray[0].registration_price.length > 0) {
      price = filteredPriceArray[0].registration_price[0]
    }

    return price
  }

  const price = getDomainPrice()

  const opacity = openPopup ? '1' : '0'
  const zIndex = openPopup ? '6' : '1'

  const sxPopup = {
    position: 'absolute',
    width: '400px',
    left: '-70px',
    bottom: '20px',
    opacity,
    zIndex,
    cursor: 'default',
    transition: 'all 0.3s',

    '@media(max-width: 1199px)': {
      width: '90%',
      left: '-30px',
    }
  }

  const handlePopupMouseEnter = () => {
    setOpenPopup(true)
  }

  const handlePopupMouseLeave = () => {
    setOpenPopup(false)
  }

  return (
    <Grid xs={12} md={6}>
      <DomainSearchResultSimilarContainerBox>
        <Grid container>
          <Grid xs={12} md={6}>
            <Box sx={{ position: 'relative' }}>
              <DomainSearchResultSimilarIconBox onMouseEnter={handlePopupMouseEnter} onMouseLeave={handlePopupMouseLeave}>i</DomainSearchResultSimilarIconBox>
              <Box sx={sxPopup}>
                <DomainSearchResultPopupBox>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{ext} </Box>
                  <Box component='span'>{description}</Box>    
                </DomainSearchResultPopupBox>
                <DomainSearchResultPopupVectorBox></DomainSearchResultPopupVectorBox>
              </Box>
            </Box>
            {
              width <= settings.padBreakpoint && (
                <Box>
                  {statusBlock}
                  <DomainSearchResultSimilarNameBox>{domain}</DomainSearchResultSimilarNameBox>
                </Box>
              )
            }
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              {button}
              <DomainSearchResultSimilarPriceBox>{price} &#8382;</DomainSearchResultSimilarPriceBox>
            </Box>
          </Grid>
          {
            width > settings.padBreakpoint && (
              <Grid xs={12} md={6}>
                {statusBlock}
                <DomainSearchResultSimilarNameBox>{domain}</DomainSearchResultSimilarNameBox>
              </Grid>
            )
          }
        </Grid>
      </DomainSearchResultSimilarContainerBox>
    </Grid>
  )
}

export default DomainSearchResultSimilarItem