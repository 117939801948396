import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import {
  DomainSearchResultContainerBox,
  DomainSearchResultGreenIconBox,
  DomainSearchResultGreenStatusBox,
  DomainSearchResultRedIconBox,
  DomainSearchResultRedStatusBox,
  DomainSearchResultNameBox,
  DomainSearchResultPriceBox,
  DomainSearchResultInfoLabelBox,
  DomainSearchResultInfoTitleBox,
  DomainSearchResultInfoValueBox
} from '../../themes/boxDomains'
import { DomainsSearchResultButton } from '../../themes/button'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import DomainSearchResultSimilar from './DomainSearchResultSimilar'

const DomainSearchResult = ({ whois, domains, specialDomains, prices }) => {
  const lang = useSelector(state => state.lang)
  
  const handleRegister = () => {
    // const langName = lang === 'en' ? 'eng' : 'geo'
    // const url = `https://domain.grena.ge/index.php?a=reg&lang=${langName}&domain=${whois.domain}`
    window.open(settings.myGrenaUrl, '_blank')
  }

  const handleTransfer = () => {
    // const langName = lang === 'en' ? 'eng' : 'geo'
    // const url = `https://domain.grena.ge/index.php?a=transfer&lang=${langName}&domain=${whois.domain}`
    window.open(settings.myGrenaUrl, '_blank')
  }

  const handleRegisterGeo = () => {
    // const geoDomainArray = whois.domain.split('.')
    // const url = `https://xn--oodcmkgd.xn--loddg6as.xn--node/index.php?a=reg&domain=${geoDomainArray[0]}`
    window.open(settings.myGrenaUrl, '_blank')
  }

  const statusColor = whois.status === 'Available' ? 'green' : 'red'

  let statusName = ''
  let button = ''
  
  if (whois.status === 'Ok') {
    statusName = constants.unavailable[lang]
    if (whois.type !== '.გე') button = <DomainsSearchResultButton variant='contained' disableRipple onClick={handleTransfer}>{constants.transfer[lang]}</DomainsSearchResultButton>
  }

  if (whois.status === 'Available') {
    statusName = constants.free[lang]
    if (whois.type !== '.გე') button = <DomainsSearchResultButton variant='contained' disableRipple onClick={handleRegister}>{constants.register[lang]}</DomainsSearchResultButton>
    else button = <DomainsSearchResultButton variant='contained' disableRipple onClick={handleRegisterGeo}>{constants.register[lang]}</DomainsSearchResultButton>
  }

  if (whois.status === 'Unavailable') {
    statusName = constants.invalidDomain[lang]
  }
  
  const statusBlock = statusColor === 'green' ? (
    <>
      <DomainSearchResultGreenIconBox></DomainSearchResultGreenIconBox>
      <DomainSearchResultGreenStatusBox>{statusName}</DomainSearchResultGreenStatusBox>
    </>
  ) : (
    <>
      <DomainSearchResultRedIconBox></DomainSearchResultRedIconBox>
      <DomainSearchResultRedStatusBox>{statusName}</DomainSearchResultRedStatusBox>
    </>
  )

  const getDomainPrice = () => {
    let price = 0

    const splittedDomain = whois.domain.split('.')

    let domainPriceName = whois.type
    if (splittedDomain.length > 2) {
      domainPriceName = '.' + splittedDomain[1] + '.' + splittedDomain[2]
    }    

    const isPremium = splittedDomain.length === 2 && splittedDomain[0].length === 2

    const filteredPriceArray = prices.filter(item => item.name === domainPriceName && item.is_premium === isPremium)

    if (filteredPriceArray.length > 0 && filteredPriceArray[0].registration_price && filteredPriceArray[0].registration_price.length > 0) {
      price = filteredPriceArray[0].registration_price[0]
    }

    return price
  }

  const price = getDomainPrice()
  
  return (
    <>
      <DomainSearchResultContainerBox>
        <Grid container spacing={{xs: 3, lg: 6}}>
          <Grid xs={12} md={9}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '30px' }}>
              {statusBlock}
            </Box>
            <DomainSearchResultNameBox>{whois.domain}</DomainSearchResultNameBox>
          </Grid>
          <Grid xs={12} md={3}>
            {
              button && (
                <>
                  <DomainSearchResultPriceBox>{price} &#8382;</DomainSearchResultPriceBox>
                  {button}
                </>
              )
            } 
          </Grid>
        </Grid>
        {
          whois.status === 'Ok' && (
            <>
          
              {
                whois.registrar && (
                  <Box sx={{ marginBottom: '20px' }}>
                    <DomainSearchResultInfoLabelBox component='span'>{constants.registrator[lang]}: </DomainSearchResultInfoLabelBox>
                    <DomainSearchResultInfoValueBox component='span'>{whois.registrar}</DomainSearchResultInfoValueBox>
                  </Box>
                )
              }

              {
                whois.creationDate && (
                  <Box>
                    <DomainSearchResultInfoTitleBox component='span'>{constants.creationDate[lang]}: </DomainSearchResultInfoTitleBox>
                    <DomainSearchResultInfoValueBox component='span'>{whois.creationDate}</DomainSearchResultInfoValueBox>
                  </Box>
                )
              }

              {
                whois.expiryDate && (
                  <Box sx={{ marginBottom: '20px' }}>
                    <DomainSearchResultInfoTitleBox component='span'>{constants.expiryDate[lang]}: </DomainSearchResultInfoTitleBox>
                    <DomainSearchResultInfoValueBox component='span'>{whois.expiryDate}</DomainSearchResultInfoValueBox>
                  </Box>
                )
              }
              
              {
                whois.registrant && (
                  <>
                    <Box sx={{ marginBottom: '20px' }}>
                      <DomainSearchResultInfoLabelBox component='span'>{constants.owner[lang]}: </DomainSearchResultInfoLabelBox>
                      <DomainSearchResultInfoValueBox  component='span'>{whois.registrant}</DomainSearchResultInfoValueBox>
                    </Box>
                    {
                      whois.registrantEmail && (
                        <Box sx={{ marginBottom: '20px' }}>
                          <DomainSearchResultInfoTitleBox component='span'>{constants.registrantEmail[lang]}: </DomainSearchResultInfoTitleBox>
                          <DomainSearchResultInfoValueBox component='span'>{whois.registrantEmail}</DomainSearchResultInfoValueBox>
                        </Box>
                      )
                    }
                    <Box>
                      <DomainSearchResultInfoTitleBox component='span'>{constants.administrator[lang]}: </DomainSearchResultInfoTitleBox>
                      <DomainSearchResultInfoValueBox component='span'>{whois.admin}</DomainSearchResultInfoValueBox>
                    </Box>
                    <Box>
                      <DomainSearchResultInfoTitleBox component='span'>{constants.administratorEmail[lang]}: </DomainSearchResultInfoTitleBox>
                      <DomainSearchResultInfoValueBox component='span'>{whois.adminEmail}</DomainSearchResultInfoValueBox>
                    </Box>
                    <Box>
                      <DomainSearchResultInfoTitleBox component='span'>{constants.techPerson[lang]}: </DomainSearchResultInfoTitleBox>
                      <DomainSearchResultInfoValueBox component='span'>{whois.tech}</DomainSearchResultInfoValueBox>
                    </Box>
                    <Box>
                      <DomainSearchResultInfoTitleBox component='span'>{constants.techPersonEmail[lang]}: </DomainSearchResultInfoTitleBox>
                      <DomainSearchResultInfoValueBox component='span'>{whois.techEmail}</DomainSearchResultInfoValueBox>
                    </Box>
                  </>
                )
              }

              <DomainSearchResultInfoLabelBox sx={{ marginTop: '20px' }}>{constants.nameServers[lang]}:</DomainSearchResultInfoLabelBox>
              {
                whois.ns.map((item, index) => (
                  <DomainSearchResultInfoValueBox key={index}>{item}</DomainSearchResultInfoValueBox>
                ))
              }

            </>
          )
        }
      </DomainSearchResultContainerBox>

      { ((whois.status === 'Ok' || whois.status === 'Available') && whois.similarDomains.length > 0) && <DomainSearchResultSimilar whois={whois} domains={domains} specialDomains={specialDomains} prices={prices} /> }
    </>
  )
}

export default DomainSearchResult